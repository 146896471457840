.main{
    width: 100%;
    height: 100vh;
    background-color: #00ffff;
    overflow: hidden;
    position: relative;
}

.headerBox{
    background-color: #272727;
    width: 100vw;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
}

.logo{

    width: 17rem;
    margin-left: 20px;
}

.menuContainer{
    /* background-color: aqua; */
    width: 530px;
    margin-right: 40px;
    margin-left: 40px;
}

.menuList{
    display: flex;
    flex-direction: row;
    list-style: none;
    color: #c3c5c9ff;
    justify-content: space-between;
}

li{
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;

}

a:link {
    text-decoration: none;
    color: #c3c5c9ff;
}
a:visited {
    text-decoration: none;
    color: #c3c5c9ff;
}
a:active {
    text-decoration: none;
    color: #c3c5c9ff;
}


.Container{
    /* overflow: hidden; */
    display: none;
    /* position: absolute; */
}

#menuToggle {
    display: block;
    /* display: none; */
    position: relative;
    /* width: 60px; */
    margin-right: 20px;
    /* float: right; */
    /* right: -40px; */
    /* top: 50px; */
    /* left: 50px; */

    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
}

#menuToggle a {
    text-decoration: none;
    color: #232323;

    transition: color 0.3s ease;
}

#menuToggle a:hover {
    color: tomato;
}


#menuToggle input {
    /* display: block; */
    width: 40px;
    height: 32px;
    position: absolute;
    /* top: -7px; */
    /* left: -5px; */

    cursor: pointer;

    opacity: 0;
    /* hide this */
    z-index: 2;
    /* and place it over the hamburger */

    -webkit-touch-callout: none;
}

/* Just a quick hamburger*/
#menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    opacity: 0.5;
    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        opacity 0.55s ease;
}

#menuToggle span:first-child {
/*   transform-origin: 0% 0%; */
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

/*  Transform all the slices of hamburger into a crossmark.*/
#menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

/* But let's hide the middle one.*/
#menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/* Ohyeah and the last one should go the other direction*/
#menuToggle input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
}

/* Make this absolute positioned at the top left of the screen*/
#menu {
    position: absolute;
    width: 300px;
    height: 100vh;
    margin: -100px 0 0 -200px;
    padding: 50px;
    padding-top: 125px;
    background: #ededed;
    list-style-type: none;
    opacity: 0.9;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(70%, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
    padding: 10px 0;
    font-size: 22px;
}

/* And let's slide it in from the lef */
#menuToggle input:checked~ul {
    transform: none;
}


#menu{
    /* overflow: hidden; */
}

/* --- Mobile */
/* For logo */
@media only screen and (max-width: 480px) {
    .logo {
        width: 12rem;
        margin-left: 1rem;
    }

  }

  /* min-width : 320px */

@media only screen and (max-width : 320px) {
   /* Styles here */
   .logo {
        width: 180px;
        margin-left: 1rem;
    }
    #menuToggle{
        margin-right: 15px;
    }
}


/* Desktop / Laptop */
@media only screen and (max-width : 970px) {
     .Container{
         display: inline;
     }
     .menuContainer{
        display: none;
     }
 }